import { useMobile } from 'common-nextjs';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { displayPrice } from 'utils';
import FeatheredOverflowContainer from '~/components/FeatheredOverflowContainer';
import Image from '~/components/Image';
import ItemThumbnailOverlayActions from '~/components/ItemThumbnail/ActionsOverlay/ItemThumbnailOverlayActions';
import ItemFreeShippingPill from '~/components/ItemThumbnail/ItemFreeShippingPill';
import { getImageUrl } from '~/components/ItemThumbnail/ItemThumbnailImage';
import ItemDetailsLink from '~/components/Links/ItemDetailsLink';
import LockerItemCheckbox from '~/components/NewLockers/BulkActions/LockerItemCheckbox';
import useThumbnailMeta from '~/hooks/useThumbnailMeta';
import EyeIcon from '~/public/static/svg/EyeIcon';
import FavoriteIcon from '~/public/static/svg/FavoriteIcon';
import { RailsThumbnailItem } from '~/typings/services/rails/item';

interface Props {
  className?: string;
  item: RailsThumbnailItem;
  onInvalidate?: () => void;

  isSelectingAll?: boolean;
  selecting: boolean;
  selected: boolean;
  onSelect: (item: RailsThumbnailItem) => void;
}

const HorizontalItemThumbnail: React.FC<Props> = ({
  className,
  item,
  onInvalidate,
  isSelectingAll,
  selecting,
  selected,
  onSelect,
}) => {
  const mobile = useMobile();
  const meta = useThumbnailMeta(item.id);

  return (
    <div className={className}>
      {/* Row with image and info */}
      <div className="mb-2 flex space-x-4">
        {selecting && (
          <div className="self-center">
            <LockerItemCheckbox
              checked={isSelectingAll || selected}
              disabled={isSelectingAll}
              itemId={item.id}
              onChange={() => onSelect(item)}
            />
          </div>
        )}

        <ItemDetailsLink item={item} className="relative block flex-shrink-0">
          <Image
            className="h-20 w-20 rounded-sm object-cover"
            src={getImageUrl(item, mobile)}
            useFastly={{
              crop: '1:1',
              height: 300,
              width: 300,
            }}
          />
        </ItemDetailsLink>

        {/* Info Column */}
        <div className="space-y-1 text-sm">
          <ItemDetailsLink item={item} className="block font-bold">
            {item.name}
            {item.label ? (
              <>
                {' - '}
                <span
                  className="font-semibold"
                  style={{ color: item.label.color }}
                >
                  {item.label.text}
                </span>
              </>
            ) : null}
          </ItemDetailsLink>

          {meta?.shipping_label?.slug === 'free_shipping' && (
            <ItemFreeShippingPill />
          )}

          <div className="text-slate-green-500 flex space-x-4 font-bold">
            <div className="flex items-center space-x-1">
              <EyeIcon className="h-4 w-4" fill="currentColor" />
              <span>{item.visit_count ?? 0}</span>
            </div>
            <div className="flex items-center space-x-1">
              <FavoriteIcon className="h-4 w-4" fill="currentColor" />
              <span>{item.favoriters_count ?? 0}</span>
            </div>
          </div>

          <div className="text-base font-bold">{displayPrice(item.price)}</div>

          {!!meta?.inventory_id && (
            <div className="mt-2 text-sm">
              Inventory ID: {meta.inventory_id}
            </div>
          )}

          {!!meta?.location_id && (
            <div className="mt-2 text-sm">Location ID: {meta.location_id}</div>
          )}

          {item.updated_at != null && (
            <div
              className="text-slate-green-500 text-xs"
              title={new Date(item.updated_at).toLocaleString()}
            >
              Last updated{' '}
              {formatDistanceToNow(new Date(item.updated_at), {
                addSuffix: true,
              })}
            </div>
          )}
        </div>
      </div>

      <FeatheredOverflowContainer className="flex items-center overflow-x-auto">
        <ItemThumbnailOverlayActions
          item={item}
          meta={meta}
          onInvalidate={onInvalidate}
          separator={<span className="text-pastel-green-500 px-4">|</span>}
        />
      </FeatheredOverflowContainer>
    </div>
  );
};

export default HorizontalItemThumbnail;
