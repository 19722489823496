'use client';

import React from 'react';
import { Button, LegacyButtonLink } from 'ui';
import ErrorMessage from '~/components/Form/ErrorMessage';
import LoadingCapableButton from '~/components/Form/LoadingCapableButton';
import AddToCart from '~/components/ItemDetails/AddToCart';
import ItemAdminActions from '~/components/ItemDetails/AdminActions/ItemAdminActions';
import ItemFavoriteButton from '~/components/ItemDetails/ItemFavoriteButton';
import ItemOwnActions from '~/components/ItemDetails/ItemOwnActions';
import SwapDetailsLink from '~/components/Links/SwapDetailsLink';
import { useSession } from '~/contexts/SessionContext';
import usePurchaseItem from '~/hooks/usePurchaseItem';
import { RailsItem } from '~/typings/services/rails/item';

interface Props {
  item: RailsItem;
  selectedVariationId?: string;
}

const ItemActionButtons: React.FC<Props> = ({ item, selectedVariationId }) => {
  const { loggedIn, user } = useSession();
  const { onBuyNow, isLoadingBuyNow, onMakeOffer, error } = usePurchaseItem(
    item,
    selectedVariationId,
  );

  const renderedError = <ErrorMessage errors={error} />;

  const hasViewSwap = item.actions.includes('view_swap');
  if (item.state !== 'available' && !hasViewSwap) {
    if (item.seller.username === user?.username) {
      return (
        <>
          {renderedError}
          <ItemOwnActions item={item} />
        </>
      );
    }

    // Item is not available, and I'm not a buyer, don't show any actions.
    return null;
  }

  const hasBuyAction = item.actions.includes('buy');
  const hasMakeOffer = item.actions.includes('offer');

  // Because web's action button have to be SOOO complicated, this
  // needs to be client side.
  if (item.auction && item.seller.username !== user?.username) {
    return (
      <>
        {renderedError}
        <div className="mb-2 mt-4 flex space-x-2">
          <ItemFavoriteButton
            item={item}
            style={{
              width: '100%',
            }}
          />
          <Button
            className="w-full"
            onClick={e => onMakeOffer(e, 'register')}
            disabled={!hasMakeOffer}
            size="large"
            id="make-offer"
          >
            Make Offer
          </Button>
        </div>
      </>
    );
  }

  if (loggedIn) {
    if (item.seller.username === user?.username) {
      return (
        <>
          <ItemOwnActions item={item} />
          {renderedError}
        </>
      );
    } else {
      return (
        <div className="space-y-2">
          {renderedError}

          {hasBuyAction && (
            <div className="flex space-x-2">
              {!hasMakeOffer && (
                <ItemFavoriteButton
                  item={item}
                  style={{
                    width: '100%',
                  }}
                />
              )}
              <LoadingCapableButton
                loading={isLoadingBuyNow}
                className="w-full"
                fluid
                onClick={onBuyNow}
                size="large"
                id="buy-now"
                text="Buy Now"
              />
            </div>
          )}

          <AddToCart
            primary={!hasBuyAction}
            item={item}
            selectedVariationId={selectedVariationId}
          />

          {hasMakeOffer && (
            <div className="flex space-x-2">
              <ItemFavoriteButton
                item={item}
                style={{
                  width: '100%',
                }}
              />

              {hasMakeOffer && (
                <Button
                  className="w-full"
                  onClick={e => onMakeOffer(e)}
                  variant={
                    item.auction || !hasBuyAction ? 'contained' : 'outlined'
                  }
                  size="large"
                  id="make-offer"
                >
                  Make Offer
                </Button>
              )}
            </div>
          )}

          {hasViewSwap && item.buyer_swap_id && (
            <LegacyButtonLink
              as={<SwapDetailsLink swapId={item.buyer_swap_id} />}
              className="mt-2 w-full"
              variant="outlined"
              size="large"
            >
              View Swap
            </LegacyButtonLink>
          )}

          <ItemAdminActions item={item} />
        </div>
      );
    }
  }

  return (
    <div className="space-y-2">
      {hasBuyAction && (
        <Button className="w-full" onClick={onBuyNow} size="large" id="buy-now">
          Buy Now
        </Button>
      )}

      <AddToCart
        primary={!hasBuyAction}
        item={item}
        selectedVariationId={selectedVariationId}
      />

      <div className="flex gap-2">
        <ItemFavoriteButton
          item={item}
          style={{
            width: '100%',
          }}
        />

        {hasMakeOffer && (
          <Button
            className="w-full"
            onClick={e => onMakeOffer(e)}
            variant={item.auction || !hasBuyAction ? 'contained' : 'outlined'}
            size="large"
            id="make-offer"
          >
            Make Offer
          </Button>
        )}
      </div>

      {renderedError}
    </div>
  );
};

export default ItemActionButtons;
